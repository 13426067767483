<template>
	<div class='h100 displayFlex flexColumn'>
		<NavbarVue :showBack="true" backgroundColor="#FF9941" color="white">Notifications</NavbarVue>
		<div class='h100 displayFlex'>
			<!-- 左侧Notification列表名 -->
			<div class="w40 h100">
				<el-table :data="NotificationList" @row-click="rowClick" :row-style="isRow" style="width: 100%" height="900" :cell-style="{cursor: 'pointer'}" :show-header="false" empty-text="No Data">
					<el-table-column prop="" label="circle" width="50">
						<template slot-scope="scope">
							<div v-if="scope.row.is_read === '1'" class="circle"></div>
						</template>
					</el-table-column>
					<el-table-column prop="theme" label="theme">
					</el-table-column>
					<el-table-column prop="send_time" label="send_time" width="200">
						<template slot-scope="scope">
							{{scope.row.send_time | dateFmt('MM/DD/YYYY')}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 右侧列表具体内容 -->
			<div class="w60 rightContent">
				<!-- theme -->
				<div class="pl50px pt30px pb30px themeClass">{{theme}}</div>
				<!-- content -->
				<div v-html="content" class="pl50px pt30px desContent" style="color: #707070;"></div>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../../components/Navbar.vue";
import { GET_username } from '../../utils/localStorage';
import Pagination from "../../components/Pagination.vue";
import { getNotificationList, updateNotificationStatus } from 'api/My'
export default {
	components: { NavbarVue, Pagination },
	data () {
		return {
			theme: '',
			content: '',
			NotificationList: [],
			currentCheckedId: ''
		};
	},
	computed: {},
	watch: {},
	methods: {
		async getList (val) {
			let param = {
				page_number: '1',
				page_size: '999',
				recipient: GET_username(),
			}
			let res = await getNotificationList(param)
			this.NotificationList = res.content.notification_list
			this.theme = this.NotificationList[0].theme
			this.content = this.NotificationList[0].content
			if (val === 'create') this.rowClick(this.NotificationList[0])
		},
		rowClick (row) {
			this.theme = row.theme
			this.content = row.content
			this.currentCheckedId = row.notification_id
			// 若当前消息未读，需要调用已读接口
			if (row.is_read === '1') {
				this.updateStatus(row.notification_id)
				row.is_read = '0'
			}
		},
		isRow ({ row }) {
			if (this.currentCheckedId.includes(row.notification_id)) {
				return {
					backgroundColor: '#FFEFBD'
				}
			}
		},
		updateStatus (notification_id) {
			updateNotificationStatus({notification_id})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.getList('create')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { }, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.rightContent {
	border-left: 1px solid #c1c1c1;
}
.themeClass {
	border-bottom: 1px solid #c1c1c1;
	color: #707070;
}
.desContent >>> p {
	line-height: 50px;
	color: #707070;
}
.el-table >>> tr {
	height: 60px;
}
.circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: 10px 30px;
	line-height: 50px;
	text-align: center;
	font-size: 24px;
	color: #fff;
	background: #1883ef;
	cursor: pointer;
	margin-left: auto;
}
</style>