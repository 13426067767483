import axios from 'utils/request.js'
export const ClientList = (data) => {
    return axios.request({
        url: "/api/crm/portal/portalClient/list",
        method: "post",
        title: "",
        data
    })
}
export const getNotificationList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/notification/getNotificationList",
        method: "post",
        title: "",
        data
    })
}
// 更新未读状态
export const updateNotificationStatus = (data) => {
    return axios.request({
        url: "/api/common/portal/common/notification/updateNotificationStatus",
        method: "post",
        title: "",
        data
    })
}
export const ClientView = (data) => {
    return axios.request({
        url: "/api/crm/portal/portalClient/view",
        method: "post",
        title: "",
        data
    })
}

export const getAppversion = (data) => {
    return axios.request({
        url: "/api/common/portal/common/appversion/getAppversion",
        method: "post",
        title: "",
        data
    })
}

export const getRecentList = (data) => {
    return axios.request({
        url: "/api/common/portal/common/submission/getRecentList",
        method: "post",
        title: "",
        data
    })
}